@use "../utilities/colors" as colors;
@use "../utilities/fonts" as fonts;
@use "../utilities/mixins" as mixins;

@use "../third-party/media-query" as mq;

footer {
    width: 100%;
    position: relative;
    z-index: 100;
    transition: all 0.2s ease-in;
    section {
        width: 50%;
        margin: 0 auto;
        .left {
            display: flex;
            align-items: center;
            small {
                color: white;
                font-weight: 200;
            }
            svg {
                path {
                    fill: white;
                }
            }
        }
        .footer-button {
            position: absolute;
            right: 20px;
            transform: translate(-50%, -50%);
            border: none;
            background-color: transparent;
            cursor: pointer;
            svg {
                transform: rotate(90deg);
                transition: all 0.2s linear;
                fill: white;
            }
        }
    }
    &.closed {
        height: 50px;
        background-color: colors.get("blue");
        section {
            height: 100%;
            .left {
                justify-content: space-between;
                width: 100%;
                height: 100%;
                color: colors.get("light");
                svg {
                    height: 30px;
                    width: auto;
                }
            }
            .right {
                display: none;
            }
            .footer-button {
                top: 50%;
                span {
                    width: 100px;
                    position: absolute;
                    top: -35px;
                    left: -30px;
                    transform: translate(-50%, -50%);
                    color: colors.get("dark", light);
                    opacity: 0.4;
                    animation: floating 2s ease-out infinite;
                }
                @keyframes floating {
                    0%,
                    20%,
                    50%,
                    80%,
                    100% {
                        transform: translateY(0);
                    }
                    40% {
                        transform: translateY(-15px);
                    }
                    60% {
                        transform: translateY(-10px);
                    }
                }
                svg {
                    transform: rotate(-90deg);
                    transition: all 0.2s linear;
                    fill: white;
                }
            }
        }
        .legal-info {
            display: none;
        }
    }
    &.opened {
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: colors.get("blue");
        padding-top: 20px;
        section {
            display: flex;
            align-items: flex-start;
            opacity: 0;
            animation: fade-in 0.1s linear forwards;
            animation-delay: 0.21s;
            .left {
                width: 30%;
                flex-direction: column;
                svg {
                    max-height: 30px;
                    max-width: 100%;
                }
            }
            .right {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: start;
                ul {
                    margin: 0;
                    list-style: none;
                    li {
                        color: colors.get("light");
                        margin-bottom: 10px;
                        &:first-child {
                            margin-bottom: 20px;
                        }
                        .link {
                            @include mixins.underline-link(light, 0.5);
                        }
                    }
                }
            }
            .footer-button {
                top: 30px;

                span {
                    display: none;
                }
            }
        }
        .legal-info {
            margin-top: 20px;
            width: 80%;
            color: colors.getColorWithOpacity("light", base, 0.4);
            font-size: 12px;
            display: flex;
            flex-wrap: wrap;
            opacity: 0;
            align-items: center;
            justify-content: center;
            animation: fade-in 0.1s linear forwards;
            animation-delay: 0.21s;
            span {
                margin: 2px;
                font-weight: 200;
                width: fit-content;
                display: inline-block;
            }
        }
    }
}

#fake-footer {
    display: none;
    height: 200px;
    width: 100%;
    background-color: colors.get("dark");
}

@keyframes fade-in {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

@include mq.media("<=1300px") {
    #footer.opened {
        section {
            .left {
                small {
                    font-size: 10px;
                }
            }
            .right {
                width: 100%;
                ul {
                    font-size: 13px;
                }
            }
        }
    }
}

@include mq.media("<=tablet") {
    #footer {
        margin-bottom: 50px;
    }
    #footer.opened {
        height: auto;
        padding: 20px 0;
        section {
            width: 90%;
            flex-direction: column;
            align-items: center;
            .left {
                width: 100%;
                text-align: center;
                svg {
                    min-width: 110px;
                }
            }
            .right {
                width: 100%;
                ul {
                    padding: 0;
                    text-align: center;
                    margin: 5%;
                }
            }
        }
        .legal-info {
            width: 100%;
        }
    }
}

@include mq.media("<=tablet-small") {
    footer.closed {
        section {
            width: 90%;
            height: 100%;
            display: flex;
            align-items: center;
            .left {
                height: 100%;
                justify-content: flex-start;
                svg {
                    height: 20px;
                }
            }
            .footer-button {
                position: unset;
                right: 20px;
                transform: unset;
                span {
                    display: none;
                }
                svg {
                    width: 20px;
                }
            }
        }
    }
}

@include mq.media("<=phone") {
    #footer.opened {
        padding-top: 40px;
        section {
            button {
                right: 50%;
                top: 10px;
                transform: translate(50%, -10px);
            }
        }
    }
}

@include mq.media("<=phone-small") {
    footer.closed {
        section {
            width: 90%;
            height: 100%;
            .left {
                height: 100%;
                justify-content: flex-start;
                h3 {
                    font-size: fonts.getSize(h4);
                    margin-right: 5%;
                }
                small {
                    font-size: fonts.getSize(small, -1);
                }
            }
            .footer-button {
                right: 20px;
                svg {
                    width: 15px;
                }
            }
        }
    }
}

@include mq.media("<=300px") {
    footer.closed {
        section {
            .left {
                svg {
                    height: 12px;
                }
                span {
                    font-size: 10px;
                }
            }
        }
    }
}
