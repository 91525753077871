@use "../third-party/normalizer";

@use "./utilities";
@use "./scrollbar";
@use "./typography";
@use "./fonts" as fonts;

@use "../components/header";
@use "../components/footer";

@use "animate.css";

@forward "../third-party/media-query" as mq-*;

* {
    text-decoration: none;
    font-family: fonts.get(sans-serif);
    box-sizing: border-box;
    scroll-behavior: smooth;
}
