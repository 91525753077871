@use "./utilities/base" as *;
@use "./utilities/colors" as colors;
@use "./utilities/fonts" as fonts;
@use "./utilities/mixins" as mixin;

#home {
    .hero {
        width: 100%;
        height: 100vh;
        background: linear-gradient(
            90deg,
            colors.get("red", dark),
            colors.get("red", light)
        );
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        #bg-img-1 {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0;
            width: 100%;
            height: 100vh;
            transform: rotate(180deg);
        }
        #bg-img-2 {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0.04;
            width: 100%;
            height: 100vh;
            transform: rotate(180deg);
        }
        .container {
            height: calc(100% - 110px);
            margin-top: 110px;
            display: flex;
            align-items: center;
            z-index: 100;
            .hero-title {
                width: 60%;
                height: 60%;
                transform: translateY(-20%);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                h1 {
                    color: colors.get("light");
                    font-weight: 700;
                    line-height: 1;
                    margin: 0;
                    text-align: end;
                    width: 100%;
                }
                h3 {
                    font-weight: 200;
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    color: colors.get("light");
                    text-align: end;
                    width: 100%;
                }
                a {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    color: colors.get("light");
                    background-color: colors.get("green");
                    transition: background-color 0.3s;
                    text-align: center;
                    margin-left: 50%;
                    width: 50%;
                    &:hover {
                        background-color: colors.get("green", light);
                    }
                }
            }
            .hero-img {
                width: 40%;
                aspect-ratio: 2 / 1;
                img {
                    width: auto;
                    height: 100%;
                    aspect-ratio: 2 / 1;
                    transform: scale(1.8) translateY(-20%);
                }
            }
        }
        .low-wave {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            z-index: 3;
        }
    }

    #about-us {
        width: 100%;
        padding: 2rem 30px 5rem 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            .au-img {
                width: 50%;
                height: auto;
                padding-left: 3rem;
                position: relative;
                img {
                    position: relative;
                    width: auto;
                    max-width: 100%;
                    height: 100%;
                    z-index: 2;
                    transform: translateZ(50px);
                }
            }
            .au-content {
                width: 50%;
                padding: 3rem;
                h2 {
                    font-weight: 700;
                    margin-top: 0;
                }
                h4 {
                    margin: 0;
                    color: colors.get("red");
                }
                p {
                    padding-bottom: 1rem;
                    color: colors.get("blue", dark);
                }
                a {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    color: colors.get("light");
                    background-color: colors.get("green");
                    transition: background-color 0.3s;
                    &:hover {
                        background-color: colors.get("green", light);
                    }
                }
            }
        }
    }

    #services {
        width: 100%;
        padding: 5rem 30px;
        .container {
            width: 70%;
            h2,
            h5 {
                font-weight: 700;
                text-align: center;
                margin: 0;
            }
            h2 {
                color: colors.get("dark");
            }
            h5 {
                color: colors.get("red");
            }
            .card-container {
                padding-top: 5rem;
                ul {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        width: 48%;
                        max-width: 700px;
                        margin: 12px;
                        border-radius: 20px;
                        padding: 20px;
                        background-color: colors.get("blue");
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        transition: all 0.5s linear;
                        .card-title {
                            width: 37%;
                            .img-box {
                                width: 100%;
                                height: 20%;
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .icon-front {
                                    width: 100%;
                                    height: auto;
                                    z-index: 2;
                                    @include mq-media(">desktop-large") {
                                        height: 130%;
                                    }
                                }
                            }
                            h5 {
                                height: 10%;
                                margin-top: 16px;
                                color: colors.get("light");
                            }
                        }
                        .card-text {
                            width: 60%;
                            padding-left: 3%;
                            display: inline-flex;
                            flex-direction: column;
                            align-items: center;
                            p {
                                height: 60%;
                                text-align: left;
                                color: colors.get("light");
                            }
                            a {
                                padding: 10px 20px;
                                border: none;
                                border-radius: 5px;
                                height: 10%;
                                color: colors.get("light");
                                background-color: colors.get("red", light);
                                transition: background-color 0.3s;
                                &:hover {
                                    background-color: colors.get("red", light);
                                }
                                span {
                                    transition: all 0.2s linear;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #cta-section {
        @include mixin.cta-section;
    }
}

@include mq-media(">desktop-large") {
    #home {
        .hero {
            .container {
                .hero-img {
                    img {
                        transform: scale(1.5) translateX(8%) translateY(-7%);
                    }
                }
            }
        }
        #services {
            .container {
                .card-container {
                    ul {
                        li {
                            .img-box {
                                .icon-front {
                                    height: 130%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<desktop-medium") {
    #home {
        .hero {
            .container {
                .hero-img {
                    img {
                        transform: scale(1.5) translateX(0%) translateY(-11%);
                    }
                }
            }
        }
    }
}

@include mq-media("<=desktop") {
    #home {
        .hero {
            .container {
                padding: 5%;
            }
        }
        #cta-section {
            @include mixin.cta-section-1150px;
        }
        #services {
            .container {
                width: 90%;
                .card-container {
                    width: 90%;
                    margin: 0 auto;
                    ul {
                        flex-wrap: wrap;
                        li {
                            height: 450px;
                            margin-bottom: 50px;
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<=tablet") {
    #home {
        .hero {
            height: calc(100vh - 100px);
            #bg-img2,
            #bg-img3 {
                height: calc(100vh - 50px);
            }
            .container {
                height: 100%;
                margin-top: 0;
                flex-direction: column;
                .hero-title,
                .hero-img {
                    width: 100%;
                    height: 50%;
                }
                .hero-title {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .hero-img {
                    img {
                        transform: none;
                    }
                }
            }
            .low-wave {
                bottom: 0px;
            }
        }
        #about-us {
            .container {
                width: 100%;
                flex-direction: column;
                .au-img {
                    width: 50%;
                    padding-right: 0;
                }
                .au-content {
                    width: 100%;
                    text-align: center;
                    padding: 0;
                }
            }
        }
        #cta-section {
            @include mixin.cta-section-850px;
        }
        #services {
            .container {
                width: 100%;
                .card-container {
                    padding-top: 2rem;
                    width: 100%;
                    ul {
                        width: 100%;
                        padding: 0;
                        flex-wrap: wrap;
                        padding: 0;
                        li {
                            height: 400px;
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<=tablet-small") {
    #home {
        #services {
            .container {
                .card-container {
                    ul {
                        li {
                            height: 500px;
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<=phone") {
    #home {
        #about-us {
            padding: 3rem 30px 2rem 30px;
            .container {
                .au-img {
                    width: 80%;
                    svg {
                        top: -50px;
                    }
                }
            }
            .au-content {
                h2 {
                    margin: 0;
                }
            }
        }
        #cta-section {
            @include mixin.cta-section-500px;
        }
        #services {
            padding: 2rem 30px;
            .container {
                width: 100%;
                .card-container {
                    width: 100%;
                    ul {
                        width: 100%;
                        flex-wrap: wrap;
                        padding: 0;
                        li {
                            width: 100%;
                            height: 400px;
                            margin: 0 auto;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}
