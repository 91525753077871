@use "./colors" as colors;
@use "./fonts" as fonts;

@use "../third-party/media-query" as mq;

@mixin underline-link($color, $opacity) {
    position: relative;
    opacity: $opacity;
    color: colors.get($color);
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
        opacity: 1;

        &::after {
            width: 100%;
            left: 0;
            background: colors.get($color);
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 1px;
        display: block;
        right: 0;
        background: colors.get($color);
        transition: width 0.2s linear;
    }
}

@mixin header-logo {
    height: 40%;
    svg {
        height: 100%;
        width: auto;
    }
    @include mq.media("<=1150px") {
        margin-left: 15px;
    }
}

@mixin page-hero {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
        90deg,
        colors.get("red", dark),
        colors.get("red", light)
    );
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .hero-text {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1,
        h5 {
            margin: 0;
            color: colors.get("light");
        }

        h5 {
            font-weight: 200;
            a {
                color: colors.get("green");
            }
        }
    }
}

@mixin article-list {
    padding: 0 30px;
    article {
        height: 100vh;
        padding: 5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            width: 50%;
            display: flex;
            background-color: colors.getColorWithOpacity("light", light, 0.5);
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 0 4rem;
            img {
                width: auto;
                height: 70vh;
            }
            h2 {
                width: 100%;
                font-weight: 700;
                color: colors.get("dark");
                margin: 0;
            }
            h4 {
                width: 100%;
                margin: 0;
                color: colors.get("green");
            }
            p {
                color: colors.get("blue", dark);
                font-weight: 400;
            }
            &.text-right {
                text-align: right;
            }
        }
    }
}

@mixin cta-section {
    padding: 50px;
    background: linear-gradient(
        90deg,
        colors.get("red", dark),
        colors.get("red", light)
    );
    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        h1 {
            text-align: center;
            color: colors.get("light");
            font-weight: 600;
            margin: 0;
        }
        a {
            padding: 10px 40px;
            border: none;
            font-size: fonts.getSize(h3);
            border-radius: 5px;
            color: colors.get("light");
            background-color: colors.get("blue");
            cursor: pointer;
            transition: background-color 0.3s;
            &:hover {
                background-color: colors.get("green", light);
            }
        }
    }
}

@mixin cta-section-1150px {
    .container {
        flex-direction: column;
    }
}

@mixin cta-section-850px {
    padding: 30px 5px;
    .container {
        h1 {
            font-size: fonts.getSize(h3);
            margin-bottom: 1rem;
        }
        a {
            font-size: fonts.getSize(h5);
        }
    }
}

@mixin cta-section-500px {
    .container {
        h1 {
            font-size: fonts.getSize(h4);
        }
        a {
            font-size: fonts.getSize(p);
            padding: 5px 20px;
        }
    }
}
