@use "./colors" as colors;

/* width */
::-webkit-scrollbar {
    width: 6.18px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: colors.getColorWithOpacity("green", light, 0.8);
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: colors.get("green");
}
